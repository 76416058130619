import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { TranslateLoader, TranslateModule, TranslatePipe } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { HttpClient, HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { APP_CONFIG, BaseAppConfig } from './app.config';
import { SplashScreen } from '@awesome-cordova-plugins/splash-screen/ngx';
import { StatusBar } from '@awesome-cordova-plugins/status-bar/ngx';

//import { NgCalendarModule } from 'ionic2-calendar';
export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http);
}
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore/';
import { AngularFireAuthModule } from '@angular/fire/compat/auth/';
import { AngularFireStorageModule } from '@angular/fire/compat/storage/';
import { DbService } from 'src/services/db.service';
import { CapitalizePipe } from 'src/services/pipes';
import { SharedModule } from './shared/shared.module';

import { AdminGuard, LoginGuard, PaymentGuard, PrivacyGuard, SchoolGuard } from './guards';
import { provideFirebaseApp } from '@angular/fire/app';
import { environment } from '../environments/environment';
import { provideFunctions, getFunctions } from '@angular/fire/functions';
import { PaymentManagerPage } from './payment-manager/payment-manager.page';
import { ModalScelta } from './question/question.page';
import { ModalSceltaVela } from './vela/vela.page';
import { LockedComponent } from './components/locked/locked.component';
import { InAppPurchase2 } from '@awesome-cordova-plugins/in-app-purchase-2/ngx';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional


export const firebaseConfig = {
    apiKey: 'AIzaSyBb9gBMwp5O_3J-2DLsnl-LFBbAVvNq7yc',
    authDomain: 'nautica-6fa51.firebaseapp.com',
    projectId: 'nautica-6fa51',
    storageBucket: 'nautica-6fa51.appspot.com',
    messagingSenderId: '1098716540546',
    appId: '1:1098716540546:web:dca35bdaa6d04c4fe44e9b',
    measurementId: 'G-003ZJ4JJ11'
};


// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

@NgModule({
    declarations: [
        AppComponent,
        ModalScelta,
        ModalSceltaVela,
        PaymentManagerPage,
    ],
    imports: [
        BrowserModule,
        IonicModule.forRoot({ scrollAssist: true }),
        AppRoutingModule,
        HttpClientModule,
        TranslateModule,
       // NgCalendarModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        AngularFireModule.initializeApp(firebaseConfig),
        AngularFirestoreModule.enablePersistence(),
        AngularFireAuthModule,
        AngularFireStorageModule,
        SharedModule,
    ],
    providers: [
        StatusBar,
        SplashScreen,
        { provide: APP_CONFIG, useValue: BaseAppConfig },
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        DbService,
        AdminGuard,
        LoginGuard,
        SchoolGuard,
        PrivacyGuard,
        PaymentGuard,
        InAppPurchase2,
    ],
    bootstrap: [AppComponent],

    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }

